<template>
  <div>
    <ContentLayout>
      <template #left>
        <div class="panel">
          <div class="flex between-center mb16">
            <PanelTitle :title="pageTitle" :title-class="['fs20']"></PanelTitle>
            <RulePopover :rule="auctionDetail.auctionRule"></RulePopover>
          </div>
          <div class="flex">
            <img v-if="auctionType == 0" class="example-auction" src="@/assets/images/example-home.png">
            <img v-if="auctionType == 1" class="example-auction" src="@/assets/images/example-hall.png">
            <img v-if="auctionType == 2" class="example-auction" src="@/assets/images/example-recom.png">
            <div class="auction">
              <div class="title">{{auctionDetail.auctionTitle}}</div>
              <div :class="['auction-row', auctionDetail.status==0?'row-gray':'']">
                <div class="flex items-center">
                  <img class="icon-aucting" src="@/assets/images/icon-aucting.png">
                  <span>{{auctionDetail.status==0?'竞拍结束':'正在竞拍'}}</span>
                </div>
                <div class="flex items-center">
                  <span class="mr8">{{auctionDetail.status == 0?'距下次开始':'距离结束时间'}}</span>
                  <CountDown :time="auctionDetail.status == 0 ? auctionDetail.startCountDown*1000 : auctionDetail.endCountDown*1000"
                  @finish="getAuctionDetail">
                    <template v-slot="scoped">
                      <span>{{`${scoped.hour}时${scoped.minute}分${scoped.second}秒`}}</span>
                    </template>
                  </CountDown>
                </div>
              </div>
              <div class="tag-list">
                <div>
                  <img src="@/assets/images/icon-safe.png">
                  <span>安全零风险</span>
                </div>
                <div>
                  <img src="@/assets/images/icon-speed.png">
                  <span>极速处理</span>
                </div>
                <div>
                  <img src="@/assets/images/icon-fair.png">
                  <span>公平公正</span>
                </div>
              </div>
              <div class="auction-params">
                <div class="row mb8">
                  <span>起拍价</span>
                  <span>{{auctionDetail.curPrice}}</span>
                  <span>截至时间</span>
                  <span>{{auctionDetail.deadlineTime}}</span>
                </div>
                <div class="row">
                  <span>加价幅度</span>
                  <span>￥{{auctionDetail.stepPrice}}</span>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div class="flex between-center mb8">
                <PanelTitle title="请选择你的任务（单选）" :title-class="['fs14']"></PanelTitle>
                <div v-if="currentTask" class="btn-rechoose" @click="openTotalTask">
                  <span>重新选择</span>
                  <img src="@/assets/images/arrow-right-theme.png">
                </div>
              </div>
              <!-- 选择任务 -->
              <CurrentTask :info="currentTask" @click.native="openTotalTask"></CurrentTask>
              <!-- 我的出价 -->
              <div class="my-auction">
                <div class="flex between-center mb8">
                  <PanelTitle title="我的出价" :title-class="['fs14']"></PanelTitle>
                  <div class="balance">任务币余额：{{userInfo.taskCyBalance}}</div>
                </div>
                <el-input placeholder="请输入出价" type="number" v-model="auctionValue">
                  <template #suffix>
                    <div style="line-height: 40px">元</div>
                  </template>
                </el-input>
                <div class="money-list">
                  <div @click="auctionValue = 50">50元</div>
                  <div @click="auctionValue = 200">200元</div>
                  <div @click="auctionValue = 500">500元</div>
                  <div @click="auctionValue = 1000">1000元</div>
                </div>
                <div class="btn-auction btn-fill-theme" @click="handleAuction">确认出价</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 参与竞价的任务 -->
        <div class="panel mt16">
          <PanelTitle title="我参与竞价的任务"></PanelTitle>
          <div class="flex items-center mt16" v-if="auctionTask">
            <div class="task-row">
              <div class="flex items-center">
                <img class="avatar" :src="auctionTask.logo || require('@/assets/images/avatar-default.png')">
                <div>
                  <div class="pname">{{auctionTask.projectName}}</div>
                  <div class="ptitle">{{auctionTask.taskTitle || auctionTask.title}}</div>
                </div>
              </div>
              <div>
                <div class="price">+{{auctionTask.price}}{{auctionTask.priceUnit}}</div>
                <div class="num">{{auctionTask.completeNum}}人已赚</div>
              </div>
            </div>
            <div class="btn-exchange btn-fill-theme" @click="openTotalTask(true)">
              <img src="@/assets/images/icon-exchange.png">
              <div>更换任务</div>
            </div>
          </div>
          <Empty tips="暂无参与的竞价任务" v-else/>
        </div>
      </template>

      <template #right>
        <PromoteSection></PromoteSection>
        <div class="panel mt16 record-panel">
          <PanelTitle title="出价记录"></PanelTitle>
          <div class="record-list" v-if="auctionDetail.auctionRecords && auctionDetail.auctionRecords.length>0">
            <div class="record" v-for="(item, index) in auctionDetail.auctionRecords" :key="index">
              <div class="flex items-center">
                <img class="avatar" :src="item.taskLogo || require('@/assets/images/avatar-default.png')">
                <div>
                  <div class="name">{{item.nickName}}</div>
                  <div class="price">{{item.price}}</div>
                </div>
              </div>
              <div class="right">
                <div class="flex items-center justify-end">
                  <div class="tag" v-if="userId == item.userId">您的出价</div>
                  <div :class="['rank', `no${index+1}`] " v-if="item.statusDesc && item.status != 1">{{item.statusDesc}}</div>
                  <div class="tag-success" v-if="item.status == 1">竞拍成功</div>
                </div>
                <div class="time">{{item.auctionTime}}</div>
              </div>
            </div>
          </div>
          <Empty v-else/>
        </div>
      </template>
    </ContentLayout>
    <!-- 全部任务弹窗 -->
    <TaskListTotalPopup ref="totalPopupRef" :list="pubTaskList" 
    @confirm="chooseTask" @loadmore="getPubTaskList($event.page)" @close="isExchangeTask = false"></TaskListTotalPopup>
  </div>
</template>

<script>
import PanelTitle from '@/components/PanelTitle'
import PromoteSection from '@/components/PromoteSection'
import Asset from '@/components/Asset'
import ContentLayout from '@/components/ContentLayout'
import TaskListChoose from '@/components/TaskListChoose'
import CountDown from '@/components/CountDown'
import Empty from '@/components/Empty'
import TaskListTotalPopup from '@/components/TaskListTotalPopup'
import RulePopover from '@/components/RulePopover'
import CurrentTask from "@/components/CurrentTask"
import { pubTasksApi, auctionTasksApi, modifyAuctionApi, taskDetailApi } from '@/api/task.js'
import { auctionDetailInfoApi, doAuctionApi } from '@/api/merchant.js'
import { mapState } from 'vuex'
export default {
  name: 'Auction',
  components: {
    PanelTitle,
    PromoteSection,
    Asset,
    ContentLayout,
    TaskListChoose,
    CountDown,
    TaskListTotalPopup,
    Empty,
    RulePopover,
    CurrentTask
  },
  data() {
    return {
      taskId:'',
      pubTaskList: [],
      auctionValue: '',
      auctionType: 0, // 0-首页顶部竞拍 1-大厅顶部竞拍 2-首页弹窗竞拍
      auctionDetail: {},
      auctionTask: null,
      userId: localStorage.getItem('USER_ID'),
      currentTask: null,
      isExchangeTask: false, // 用于选择任务后判断是否是更换竞价任务
    }
  },
  computed: {
    ...mapState(['userInfo']),
    pageTitle() {
      const title = this.$route.query.title
      if(title) {
        return title
      } else {
        const map = {
          0: '首页竞拍',
          1: '大厅竞拍',
          2: '弹窗竞拍'
        }
        return map[this.$route.query.type]
      }
    }
  },
  watch: {
    $route(to, from) {
      if(to.name == from.name) {
        this.auctionType = this.$route.query.type
        this.getPubTaskList()
        this.getAuctionDetail()
        this.getAuctionTaskList()
      }
    }
  },
  created() {
    this.auctionType = this.$route.query.type
    this.getPubTaskList()
    this.getAuctionDetail()
    this.getAuctionTaskList()
  },
  methods: {
    getPubTaskList(pageNo = 1) {
      pubTasksApi({
        startPage: pageNo-1<0 ? 0 : pageNo-1,
        type: 0, // 0 已上架
      }).then((res) => {
        const list = res.data.taskListItems || []
        if(pageNo == 1) {
          this.pubTaskList = list
        } else {
          this.pubTaskList = this.pubTaskList.concat(list)
          if(list.length == 0) this.$tips({message: '没有更多数据了', type: 'warning'})
        }
      }).catch((err) => {
        this.$tips({ message: err, type: "error" });
      });
    },
    getAuctionDetail() {
      auctionDetailInfoApi({'auctionType': this.auctionType}).then(res => {
        console.log('竞拍详情',res)
        this.auctionDetail = res.data
        if(res.data.auctionRecords && res.data.auctionRecords.length > 0) {
          const userId = localStorage.getItem('USER_ID')
          const target = res.data.auctionRecords.find(item => item.userId == userId)
          if(target) this.getUserAuctionTask(target.taskId)
        }
        
        this.auctionTask = res.data.auctionTask
      }).catch(err => {
        this.$tips({ message: err, type: "error" });
      });
    },
    getAuctionTaskList() {
      auctionTasksApi({'auctionType': this.auctionType}).then(res => {
        console.log('竞拍任务列表', res)
      }).catch(err => {
        this.$tips({ message: err, type: "error" })
      })
    },
    openTotalTask(isExchangeTask) {
      if(isExchangeTask === true) this.isExchangeTask = isExchangeTask
      this.$refs.totalPopupRef.open()
    },
    chooseTask(e) {
      if(!this.isExchangeTask) {
        this.taskId = e.detail.taskId
        this.currentTask = e.detail
      } else {
        this.exchangeAutionTask(e.detail.taskId)
      }
      
    },
    handleAuction() {
      if(!this.taskId) return this.$tips({ message: '请选择竞拍任务', type: "warning" })
      if(!this.auctionValue.toString().trim()) return this.$tips({ message: '请输入竞拍价格', type: "warning" })
      doAuctionApi({
        'auctionType': this.auctionType,
        'price': this.auctionValue.toString(),
        'taskId': this.taskId
      }).then(res => {
        console.log('竞拍结果', res)
        this.$tips({ message: res.msg, type: "success" })
        this.getAuctionDetail()
        this.auctionValue = ''
        this.$store.dispatch('GET_USER_INFO') // 更新余额
      }).catch(err => {
        this.$tips({ message: err, type: "error" });
      });
    },
    exchangeAutionTask(taskId) {
      modifyAuctionApi({
        'auctionType': this.auctionType,
        'taskId': taskId
      }).then(res => {
        this.$tips({ message: res.msg, type: "success" });
        this.getAuctionDetail()
      }).catch(err => {
        this.$tips({ message: err, type: "error" });
      })
    },
    getUserAuctionTask(taskId) {
      taskDetailApi({taskId}).then(res => {
        console.log('当前进价任务', res)
        this.auctionTask = res.data
      }).catch(err => {
        this.$tips({ message: err, type: "error" });
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.example-auction {
  width: 432px;
}
.btn-rechoose {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--theme-color);
  cursor: pointer;
  img {
    width: 18px;
    height: 18px;
    margin-left: 4px;
  }
}
.auction {
  flex: 1;
  margin-left: 24px;
}
.title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}
.auction-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding: 0 12px;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  background: linear-gradient(to right, #FF5C19, #FE9800);
  margin-bottom: 8px;
  .icon-aucting {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
}
.row-gray {
  background: #B0B3BF;
}
.tag-list {
  display: flex;
  align-items: center;
  color: #65666F;
  font-size: 12px;
  img {
    width: 16px;
    height: 16px;
    margin-right: 2px;
  }
  &>div {
    margin-right: 20px;
  }
}
.auction-params {
  font-size: 12px;
  color: #B0B3BF;
  padding: 16px 0;
  margin-bottom: 20px;
  .row {
    display: flex;
    span {
      flex: 1;
    }
  }
}

// 我的出价
.my-auction {
  margin-top: 24px;
  .balance {
    font-size: 14px;
    color: #65666F;
  }
  .money-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    &>div {
      width: 90px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      border: 1px solid #E8E8E8;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
    }
  }
  .btn-auction {
    height: 42px;
    line-height: 42px;
    text-align: center;
    border-radius: 8px;
    font-size: 16px;
    margin-top: 8px;
    cursor: pointer;
  }
}

// 参与竞价的任务
.task-row {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid var(--theme-color);
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 12px;
  }
  .pname {
    font-size: 12px;
    color: #65666F;
  }
  .ptitle {
    font-size: 14px;
    margin-top: 4px;
    font-weight: bold;
  }
  .price {
    font-size: 14px;
    font-weight: bold;
    color: var(--theme-color-red);
  }
  .num {
    font-size: 12px;
    color: #65666F;
    margin-top: 4px;
  }
}
.btn-exchange {
  padding: 6px;
  text-align: center;
  font-size: 14px;
  border-radius: 8px;
  margin-left: 16px;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
    margin-bottom: 4px;
  }
}

// 出价记录
.record-panel {
  ::-webkit-scrollbar {
    width: 8px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 32px;
  }

  ::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 32px;
  }
}
.record-list {
  max-height: 400px;
  overflow-y: auto;
  margin-right: -24px;
  padding-right: 16px;
  padding-top: 8px;
}
.record {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 8px;
  }
  .name {
    font-size: 14px;
    font-weight: bold;
    max-width: 65px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .price {
    color: var(--theme-color-red);
    font-size: 14px;
    font-weight: bold;
    margin-top: 4px;
  }
  .right {
    text-align: right;
  }
  .time {
    font-size: 12px;
    color: #B0B3BF;
    margin-top: 4px;
  }
  .tag {
    height: 22px;
    line-height: 22px;
    padding: 0 4px;
    border: 1px solid var(--theme-color-red);
    border-radius: 4px;
    font-size: 12px;
    color: var(--theme-color-red);
  }
  .tag-success {
    height: 22px;
    line-height: 22px;
    padding: 0 4px;
    border-radius: 4px;
    margin-left: 4px;
    background-color: #25CF36;
	  color: #fff;
    font-size: 12px;
  }
  .rank {
    height: 22px;
    line-height: 22px;
    font-size: 14px;
    font-weight: bold;
    margin-left: 4px;
    border-radius: 4px;
    padding: 0 4px;
  }
  .no1 {
    background-color: #F5E412;
    color: #443900;
  }
  .no2 {
    background-color: #BCD9E9;
    color: #003260;
  }
  .no3 {
    background-color: #E1AC6F;
    color: #613A00;
  }
  .no4 {
    background-color: #2A2A2A;
    color: #fff;
  }
  .out {
    background-color: #B0B3BF;
    color: #fff;
  }
}
</style>